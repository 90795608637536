import styled from "styled-components";

const Text = styled.div`
  width: 100%;
  font-size: ${props => (props.$fontSize ? props.$fontSize : '26px')};
  font-weight: ${props => (props.$fontWeight ? props.$fontWeight : '300')};
  color: ${props => (props.$color ? props.$color : ' #ffffff')};
  line-height: 1.5em;
  text-align: ${props => (props.$align ? props.$align : 'center')};
  word-break: break-all;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 7vw 36vh;
  display: flex;
  flex-direction: row;
`;

export {
  Text,
  Wrapper,
}