import React, {useEffect} from 'react';
import Language from '../../../atom/Language';
import {useRecoilValue} from 'recoil';
import {Container, HeadLine, HomeComponentWrap, MainImgWrap, Path} from '../../../components/style';
import Header from '../../../components/Header';
import {Trans} from 'react-i18next';
import {t} from 'i18next';
import Video from '../../../components/Video';
import {Desktop, Mobile} from '../../../utils/MediaQuery';
import {useNavigate} from 'react-router-dom';
import Footer from '../../../components/Footer';
import { Text, Wrapper } from './style';

const Moral = () => {
  const language = useRecoilValue(Language);
  const navigate = useNavigate();
  useEffect(() => {
    if (language !== 'KOR') {
      navigate('/');
    }

    window.scrollTo(0, 0);
    document.querySelector('.container')?.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <MainImgWrap>
        <Video
          page="moral"
          src={
            window.innerWidth > 1280
              ? 'https://aribio.s3.ap-northeast-2.amazonaws.com/static/AB0400PB_VD.mp4'
              : window.innerWidth > 900
              ? 'https://aribio.s3.ap-northeast-2.amazonaws.com/static/AB1400PB_VD.mp4'
              : 'https://aribio.s3.ap-northeast-2.amazonaws.com/static/AB2200PB_VD.mp4'
          }
        />
      </MainImgWrap>
      <Header />
      <Path>
        <span style={{opacity: '0.8'}}>{`HOME > ABOUT US > `}</span>
        MORAL MANAGEMENT
      </Path>
      <HomeComponentWrap style={{height: '100vh'}}>
        <HeadLine $className="midsize">
          <Trans i18nKey={'moral.headline'} components={{1: <br />}} />
        </HeadLine>
        <img
          src={process.env.PUBLIC_URL + '/assets/icons/scroll-button.svg'}
          alt="home"
          style={{
            position: 'absolute',
            right: '7vw',
            bottom: window.innerWidth > 900 ? '5vw' : '7vh',
            height: window.innerWidth > 1280 ? '24px' : '14px',
          }}
        />
      </HomeComponentWrap>
      <div style={{margin: '0', padding: '0', position: 'relative'}}>
        <Desktop>
          <div style={{backgroundColor: '#121212'}}>
            <HomeComponentWrap style={{padding: window.innerWidth > 1280 ? '22vh 7vw 18vh' : '14vh 7vw 11vh'}}>
              <Text
                $fontSize={window.innerWidth > 1280 ? '50px' : '34px'}
                $fontWeight="500"
                $color="#ffffff"
                style={{lineHeight: '1em', marginTop: 3}}>
                윤리경영
              </Text>
              <div
                style={{
                  alignSelf: 'center',
                  width: window.innerWidth > 1280 ? '60px' : '40px',
                  height: '2px',
                  border: '1px solid #ffffff',
                  margin: window.innerWidth > 1280 ? '80px 0' : '52px 0',
                }}
              />
              <Text $fontSize={window.innerWidth > 1280 ? '26px' : '18px'} $fontWeight="400" $color="#9A9A9A">
                MORAL MANAGEMENT
              </Text>
            </HomeComponentWrap>
            <Wrapper>
              <div style={{flex: 1, marginTop: 6, marginLeft: 6}}>
                <div>
                  <div
                    style={{
                      alignSelf: 'center',
                      width: window.innerWidth > 1280 ? '60px' : '40px',
                      height: '2px',
                      border: '1px solid #939598',
                      marginBottom: window.innerWidth > 1280 ? '66px' : '39px',
                    }}
                  />
                  <Text $fontSize={window.innerWidth > 1280 ? '32px' : '21px'} $fontWeight="500" $color="#EAEAEA" style={{textAlign: 'left', whiteSpace: 'break-spaces', lineHeight: '1.3em'}}>
                    {`아리바이오\n윤리규정`}
                  </Text>
                </div>
              </div>
              <div style={{flex: 2, marginTop: window.innerWidth > 1280 ? '72px' : '47px', marginRight: 6}}>
                <Text $fontSize={window.innerWidth > 1280 ? '28px' : '18px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제1장 총칙</Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제1조(목적)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  이 규정은 주식회사 아리바이오(이하 “회사”라 한다)와 회사의 사원(비정규직을 포함한 회사내 모든 임직원을 말한다. 이하 같다)이 올바른 의사결정과 윤리적 판단을 하는 기준을 제공하고 윤리적 기업경영을 함으로써 사회, 고객, 구성원, 주주, 거래처 등 다양한 이해관계자의 가치 제고를 통해 사회에 공헌하는 회사를 지향하는 규정을 정함을 목적으로 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제2조(적용범위)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 이 규정은 회사의 모든 경영활동 및 모든 사원에 적용한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 이 규정이 타 회사규정과 상충될 경우 본 윤리규정을 우선으로 하여 적용한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제3조(기본원칙)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사가 추구하는 윤리경영은 회사와 이해관계자 상호간의 균형을 이루는 방향을 원칙으로 하고 경쟁사 및 경쟁제품에 맞서 상도의에 따라 공정하게 경쟁하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 고객 만족을 우선하여 고객으로부터 신뢰를 얻어야 하며, 고객과 더불어 발전하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  3. 회사는 사원이 자발적이고 의욕적으로 일할 수 있는 환경을 조성하도록 노력하여야 하고, 사원은 회사의 발전 및 이해관계자의 가치 창출에 기여하도록 노력하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  4. 회사는 주주의 가치 창출을 위해 회사의 가치를 높여야 하며, 이를 위해 경영의 투명성을 제고하고 효율적인 경영을 하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  5. 회사는 공정경쟁, 사원복지구현, 이익창출, 주주가치극대화, 고객만족을 통하여 최종적으로 사회에 공헌함을 목적으로 한다.
                </Text>
                <br />
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '28px' : '18px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제2장 사원의 기본윤리</Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제4조(사원의 기본윤리)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 사원은 회사규정 복무규정을 성실히 준수한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 사원은 회사의 구성원으로서 긍지와 자부심을 가지며 항상 정직하고 성실한 자세를 가진다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  3. 사원은 본 윤리규정과 회사규정 복무규정 및 사회 제반 법령과 도덕적 규정을 준수하고 높은 윤리적 가치관을 가지고 스스로의 양심에 어긋나지 않도록 하여야 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제5조(사명완수)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  사원은 회사의 경영이념과 목표를 공유, 공감하여 맡은 바 책임을 완수하도록 성실히 노력하여야 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제6조(자기계발)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  사원은 글로벌 시대에 맞게 스스로 끊임없는 자기계발을 노력하여야 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제7조(이해상충)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 사원은 직무를 수행함에 있어 회사의 이해와 상충되는 어떠한 행위나 이해관계도 피하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 사원은 회사의 이해관계와 사회 도덕적 기준이 상충될 경우 높은 윤리적 잣대를 기준으로 올바른 의사결정을 하여야 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제8조(수수금지)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 사원은 회사규정 상벌규정 제10조(수수금지)를 지켜야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                {`2. 사원은 불가피하게 또는 인지가 안된 상태에서 추후 인지하게 된 금품, 향응의 수수에 대하여 즉시 상사에게 보고하고 회사의 지시를 따라야 한다. 다만, 다음 각호의 경우는 예외로 한다.\n① 거래처의 홍보 및 행사 기념품 : 거래처 명칭이나 로고가 표시되어 있으며, 그 전체 가격이 사회통념상 인정되는 수준일 것\n② 경조금 : 전통적 상부상조의 예절에 따른 사회통념상 인정되는 금액이나 물품`}
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  3. 본 조는 회사에도 적용한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제9조(상호관계)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 사원은 상호간에 사회에서 요구하는 기본예의를 지켜야 하며 불손한 언행이나 타 사원을 비방하는 등의 언행을 하여서는 아니된다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 사원은 파벌을 조성하거나 차별대우를 하여서는 아니된다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  3. 사원 간에 부당한 청탁이나 승진 등의 목적으로 금품수수를 하여서는 아니된다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  4. 사원은 기타 법령 및 사회규범상 허용범위를 벗어나는 언행을 하여서는 아니된다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제10조(투명성 및 정보보호)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 사원은 회계정보 등 모든 정보를 정당하고 투명하게 취득, 관리하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 사원은 특정 개인이나 부서의 이익을 위해 중요한 정보를 회사내에서 은폐하거나 독점하지 않는다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  3. 사원은 회사규정 보안규정, 내부회계관리규정 등의 정보보호 관련 규정과 투명성 관련 규정에 상호 어긋남이 없도록 주의하여야 한다.
                </Text>
                <br />
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '28px' : '18px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제3장 사원에 대한 윤리</Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제11조(사원 존중)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사는 사원에 대한 믿음과 애정을 가지고 사원 개개인을 존엄한 인격체로 대하며 종교적정치적 의사와 개인 사생활을 존중한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 사원과 공동 운명체임을 인식하고 회사의 경영활동 수행자로서 사원의 중요성을 인식한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제12조(공정 처우)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  회사는 성별, 학벌, 연령, 종교, 출신지역, 혈연, 심신장애 등에 따른 주관적 기준을 배제하고 차별대우를 하지 않으며, 사원 개인의 객관적 능력과 자질에 따라 균등 기회를 부여하고, 직무 수행에 따른 결과와 업적에 따라 공정한 평가와 보상을 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제13조(건강, 안전)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  회사는 사원의 건강과 안전을 위해 쾌적한 환경의 업무 공간을 제공하여야 하고, 관련 제도와 규정을 마련하고 시행하며, 관련 교육을 정기적으로 실시하는 등 법령 및 회사규정 등을 준수한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제14조(인재육성)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  회사는 사원의 직무능력개발과 자기계발을 적극 지원하여 전문적이고 창의적인 인재로 육성되도록 노력하여야 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제15조(사원 행복)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  회사는 사원이 삶의 질을 향상시킬 수 있는 동호회, 교육, 복리후생 등을 적극 지원하여 사원의 행복을 위해 노력하여야 한다.
                </Text>
                <br />
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '28px' : '18px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제4장 고객에 대한 윤리</Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제16조(고객만족)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사와 사원은 고객을 우선으로 하여 고객의 입장에서 생각하고 고객을 존중하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사와 사원은 고객의 요구와 기대를 파악하고 제품 개발단계에서부터 생산, 유통 및 사후 처리까지 최고의 제품과 최상의 서비스를 제공하기 위해 노력하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  3. 회사와 사원은 고객의 의견과 제안사항을 최대한 수용하고 불만사항에 대해서는 최대한 신속히 공정하게 처리하도록 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  4. 회사와 사원은 고객의 유무형 자산을 소중히 인식하고 고객의 이익을 침해하지 아니한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  5. 회사와 사원은 고객이 합리적으로 제품과 서비스를 선택할 수 있고 고객 편의를 높일 수 있도록 관계법령 및 회사규정이 허용하는 범위에서 최대한 정확한 정보를 제공하여야 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제17조(고객 정보보호)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사와 사원은 고객정보 보호 관련 법령을 준수하고 고객정보 보호를 위한 업무 프로세스를 지속적으로 점검 개선하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 고객 동의가 있거나 관계법령상 허용 또는 제출 요구되는 경우를 제외하고는 고객정보를 목적 외 용도로 이용하거나 제3자에게 제공하지 않는다.
                </Text>
                <br />
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '28px' : '18px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제5장 거래처에 대한 윤리</Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제18조(준수사항)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사는 모든 사업 및 영업활동을 함에 있어서 해당 국가 및 지역의 사회적 관습을 존중하고 제반 법규와 규범을 준수한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 거래업체의 전통과 특성을 존중한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제19조(자유경쟁)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  회사는 자유경쟁의 원칙에 따라 시장경제질서를 존중하고 경쟁사 및 경쟁제품과 선의의 경쟁을 추구한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제20조(상생추구)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사는 협력업체와 상호이익 및 공동발전을 추구한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 거래업체에 대한 우월적 지위를 이용하여 부당 행위를 하지 않는다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제21조(정보보호)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  회사는 거래업체의 정보와 영업비밀을 보호하여야 하며, 거래처의 동의가 있거나 법률상 허용되거나 제출 요구되는 경우를 제외하고 제3자에게 누설하거나 공표하지 않는다.
                </Text>
                <br />
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '28px' : '18px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제6장 주주에 대한 윤리</Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제22조(기업가치)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사는 주식회사로서 주주가 곧 회사의 주인임을 인식한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 효율 경영으로 이익창출을 통해 기업가치를 극대화하고 그 성과를 주주와 공유한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제23조(주주권익)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사는 투명경영을 실천하며 주주의 정당한 요구 및 제안을 존중한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 주주의 알 권리를 보장하며 적정, 정확, 적시에 공시자료를 통해 회사의 경영상황을 알린다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  3. 회사는 미공개자료를 이용하여 내부적, 개인적 이익을 취하거나 제3자에게 이익을 주지 않으며 주주의 이익보호를 위해 최선을 다한다.
                </Text>
                <br />
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '28px' : '18px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제7장 사회에 대한 윤리</Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제24조(사회발전 기여)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사는 합리적이고 효율적인 경영을 통해 성장과 이익창출을 함으로써 사회적 부를 창조하여 사회 발전에 이바지한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 사회의 일원으로서 지역사회의 요구와 제안을 최대한 수용하고 문제를 해결하는데 최선을 다하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  3. 회사는 봉사활동, 재난구호, 기부 등 사회발전에 도움이 되는 활동에 적극 참여한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제25조(정치적 중립)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사는 정치적 중립을 지키며, 회사의 유무형 자산, 인력 등을 정치적 목적으로 사용하는 일체의 활동을 하지 않는다. 다만, 세법에서 인정하는 적법한 정치 기부금은 예외로 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 사원의 참정권과 정치적 의사표현을 존중하며, 사원은 회사 내부에서 정치적 활동을 하지 않는다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  3. 사원은 개인의 정치적 견해와 의사표현이 회사의 입장으로 오해 받지 않도록 주의하여야 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제26조(노사화합)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                회사와 사원은 노사 모두가 회사 경영활동의 주체임을 인식하고 신뢰와 협력을 바탕으로 건전한 노사문화를 형성하여 사회의 모범이 된다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제27조(환경보호)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  회사는 환경보호 관련 국제기준 및 관계법령 등을 준수하고 환경보호 및 오염방지를 위하여 환경친화적 경영을 한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제28조(재해예방)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  회사와 사원은 재해의 발생이 사회에 악영향을 미침을 인식하며 보건 및 안전에 관한 사회 규범, 관계법령 및 회사규정 안전관리규정 등을 준수하여 재해를 예방하여야 한다.
                </Text>
                <br />
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '28px' : '18px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제8장 보칙</Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제29조(책임자)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 회사의 윤리경영에 대한 최종 책임은 대표이사에게 있다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 대표이사 이하 각 조직의 장은 본 규정 및 윤리경영에 대해 소속직원을 감독, 관리, 교육할 책임이 있다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  3. 각 조직의 장은 연1회 이상의 정기적 교육을 실시하여야 하며, 신입직원은 입사 후 즉시 교육하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  4. 감사는 윤리책임자로서 대표이사를 보좌하며 제2항 및 제3항을 감독 관리한다.
                </Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제30조(상담, 신고)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '16px' : '12px', textIndent: window.innerWidth > 1280 ? '-16px' : '-12px'}}>
                  1. 사원은 본 윤리규정 및 관계법령, 기타 사회규범에 어긋나는 사실에 대하여 인지하는 즉시 상사에게 보고하며, 상황에 따라 보고가 여의치 않은 경우 윤리책임자에게 상담 신청 및 신고를 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  2. 회사는 본 윤리규정 및 관계법령, 기타 사회규범에 어긋나는 사실에 대한 기명 혹은 익명의 제보를 받는 장치를 설치하고 윤리책임자가 감독 관리한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  3. 윤리책임자는 상담자, 신고자, 제보자를 보호하여야 한다.
                </Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces', paddingLeft: window.innerWidth > 1280 ? '21px' : '16px', textIndent: window.innerWidth > 1280 ? '-21px' : '-16px'}}>
                  4. 윤리책임자는 상담, 신고, 제보 사실에 대해 조사 확인하고 필요한 조치를 취하여야 한다.
                </Text>
                <br />
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '28px' : '18px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>부칙</Text>
                <br />
                <br />
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제1조(시행일)</Text>
                <Text $fontSize={window.innerWidth > 1280 ? '20px' : '14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  이 규정은 2018년 1월 1일부터 시행한다.
                </Text>
              </div>
            </Wrapper>
          </div>
        </Desktop>

        <Mobile>
          <div style={{backgroundColor: '#121212'}}>
            <HomeComponentWrap style={{padding: '17vh 7vw 5vh'}}>
              <Text
                $fontSize={'16px'}
                $fontWeight="400"
                $color="#939598"
                style={{lineHeight: '1em', marginBottom: 12}}>
                MORAL MANAGEMENT
              </Text>
              <Text
                $fontSize={'22px'}
                $fontWeight="500"
                $color="#ffffff"
                style={{lineHeight: '1em'}}>
                윤리경영
              </Text>
              <div
                style={{
                  alignSelf: 'center',
                  width: '20px',
                  height: '2px',
                  border: '1px solid #ffffff',
                  margin: '28px 0',
                }}
              />
              <Text $fontSize={'18px'} $fontWeight="400" $color="#D3D3D3">
                아리바이오 윤리규정
              </Text>
            </HomeComponentWrap>
            <Wrapper style={{padding: '0 12px 250px 18px'}}>
              <div style={{flex: 2, marginTop: 6, marginRight: 6}}>
                <Text $fontSize={'17px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제1장 총칙</Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제1조(목적)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`이 규정은 주식회사 아리바이오(이하 “회사”라 한다)와\n회사의 사원(비정규직을 포함한 회사내 모든 임직원을\n말한다. 이하 같다)이 올바른 의사결정과 윤리적 판단을 하는\n기준을 제공하고 윤리적 기업경영을 함으로써 사회, 고객,\n구성원, 주주, 거래처 등 다양한 이해관계자의 가치 제고를\n통해 사회에 공헌하는 회사를 지향하는 규정을 정함을\n목적으로 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제2조(적용범위)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces',}}>
                  1. 이 규정은 회사의 모든 경영활동 및 모든 사원에 적용한다.
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 이 규정이 타 회사규정과 상충될 경우 본 윤리규정을\n우선으로 하여 적용한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제3조(기본원칙)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 회사가 추구하는 윤리경영은 회사와 이해관계자 상호간의\n균형을 이루는 방향을 원칙으로 하고 경쟁사 및 경쟁제품에\n맞서 상도의에 따라 공정하게 경쟁하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사는 고객 만족을 우선하여 고객으로부터 신뢰를\n얻어야 하며, 고객과 더불어 발전하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`3. 회사는 사원이 자발적이고 의욕적으로 일할 수 있는\n환경을 조성하도록 노력하여야 하고, 사원은 회사의 발전 및\n이해관계자의 가치 창출에 기여하도록 노력하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`4. 회사는 주주의 가치 창출을 위해 회사의 가치를 높여야\n하며, 이를 위해 경영의 투명성을 제고하고 효율적인 경영을\n하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`5. 회사는 공정경쟁, 사원복지구현, 이익창출, 주주가치\n극대화, 고객만족을 통하여 최종적으로 사회에 공헌함을\n목적으로 한다.`}
                </Text>
                <br />
                <br />
                <Text $fontSize={'17px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제2장 사원의 기본윤리</Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제4조(사원의 기본윤리)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  1. 사원은 회사규정 복무규정을 성실히 준수한다.
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 사원은 회사의 구성원으로서 긍지와 자부심을 가지며\n항상 정직하고 성실한 자세를 가진다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`3. 사원은 본 윤리규정과 회사규정 복무규정 및 사회 제반\n법령과 도덕적 규정을 준수하고 높은 윤리적 가치관을\n가지고 스스로의 양심에 어긋나지 않도록 하여야 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제5조(사명완수)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`사원은 회사의 경영이념과 목표를 공유, 공감하여 맡은 바\n책임을 완수하도록 성실히 노력하여야 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제6조(자기계발)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`사원은 글로벌 시대에 맞게 스스로 끊임없는 자기계발을\n노력하여야 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제7조(이해상충)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 사원은 직무를 수행함에 있어 회사의 이해와 상충되는\n어떠한 행위나 이해관계도 피하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 사원은 회사의 이해관계와 사회 도덕적 기준이 상충될\n경우 높은 윤리적 잣대를 기준으로 올바른 의사결정을\n하여야 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제8조(수수금지)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 사원은 회사규정 상벌규정 제10조(수수금지)를\n지켜야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                {`2. 사원은 불가피하게 또는 인지가 안된 상태에서 추후\n인지하게 된 금품, 향응의 수수에 대하여 즉시 상사에게\n보고하고 회사의 지시를 따라야 한다. 다만, 다음 각호의\n경우는 예외로 한다.\n① 거래처의 홍보 및 행사 기념품 : 거래처 명칭이나 로고가\n표시되어 있으며, 그 전체 가격이 사회통념상 인정되는\n수준일 것\n② 경조금 : 전통적 상부상조의 예절에 따른 사회통념상\n인정되는 금액이나 물품`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  3. 본 조는 회사에도 적용한다.
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제9조(상호관계)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 사원은 상호간에 사회에서 요구하는 기본예의를 지켜야\n하며 불손한 언행이나 타 사원을 비방하는 등의 언행을\n하여서는 아니된다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  2. 사원은 파벌을 조성하거나 차별대우를 하여서는 아니된다.
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`3. 사원 간에 부당한 청탁이나 승진 등의 목적으로\n금품수수를 하여서는 아니된다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`4. 사원은 기타 법령 및 사회규범상 허용범위를 벗어나는\n언행을 하여서는 아니된다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제10조(투명성 및 정보보호)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 사원은 회계정보 등 모든 정보를 정당하고 투명하게 취득,\n관리하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 사원은 특정 개인이나 부서의 이익을 위해 중요한 정보를\n회사내에서 은폐하거나 독점하지 않는다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`3. 사원은 회사규정 보안규정, 내부회계관리규정 등의\n정보보호 관련 규정과 투명성 관련 규정에 상호 어긋남이\n없도록 주의하여야 한다.`}
                </Text>
                <br />
                <br />
                <Text $fontSize={'17px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제3장 사원에 대한 윤리</Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제11조(사원 존중)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 회사는 사원에 대한 믿음과 애정을 가지고 사원 개개인을\n존엄한 인격체로 대하며 종교적정치적 의사와 개인 사생활을\n존중한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사는 사원과 공동 운명체임을 인식하고 회사의\n경영활동 수행자로서 사원의 중요성을 인식한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제12조(공정 처우)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`회사는 성별, 학벌, 연령, 종교, 출신지역, 혈연, 심신장애\n등에 따른 주관적 기준을 배제하고 차별대우를 하지 않으며,\n사원 개인의 객관적 능력과 자질에 따라 균등 기회를\n부여하고, 직무 수행에 따른 결과와 업적에 따라 공정한\n평가와 보상을 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제13조(건강, 안전)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`회사는 사원의 건강과 안전을 위해 쾌적한 환경의 업무\n공간을 제공하여야 하고, 관련 제도와 규정을 마련하고\n시행하며, 관련 교육을 정기적으로 실시하는 등 법령 및\n회사규정 등을 준수한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제14조(인재육성)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`회사는 사원의 직무능력개발과 자기계발을 적극 지원하여\n전문적이고 창의적인 인재로 육성되도록 노력하여야 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제15조(사원 행복)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`회사는 사원이 삶의 질을 향상시킬 수 있는 동호회, 교육,\n복리후생 등을 적극 지원하여 사원의 행복을 위해\n노력하여야 한다.`}
                </Text>
                <br />
                <br />
                <Text $fontSize={'17px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제4장 고객에 대한 윤리</Text>
                <br />
                <Text $fontSize={ '14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제16조(고객만족)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 회사와 사원은 고객을 우선으로 하여 고객의 입장에서\n생각하고 고객을 존중하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사와 사원은 고객의 요구와 기대를 파악하고 제품\n개발단계에서부터 생산, 유통 및 사후 처리까지 최고의\n제품과 최상의 서비스를 제공하기 위해 노력하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`3. 회사와 사원은 고객의 의견과 제안사항을 최대한\n수용하고 불만사항에 대해서는 최대한 신속히 공정하게\n처리하도록 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`4. 회사와 사원은 고객의 유무형 자산을 소중히 인식하고\n고객의 이익을 침해하지 아니한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`5. 회사와 사원은 고객이 합리적으로 제품과 서비스를\n선택할 수 있고 고객 편의를 높일 수 있도록 관계법령 및\n회사규정이 허용하는 범위에서 최대한 정확한 정보를\n제공하여야 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제17조(고객 정보보호)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 회사와 사원은 고객정보 보호 관련 법령을 준수하고\n고객정보 보호를 위한 업무 프로세스를 지속적으로 점검\n개선하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사는 고객 동의가 있거나 관계법령상 허용 또는 제출\n요구되는 경우를 제외하고는 고객정보를 목적 외 용도로\n이용하거나 제3자에게 제공하지 않는다.`}
                </Text>
                <br />
                <br />
                <Text $fontSize={'17px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제5장 거래처에 대한 윤리</Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제18조(준수사항)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 회사는 모든 사업 및 영업활동을 함에 있어서 해당 국가 및\n지역의 사회적 관습을 존중하고 제반 법규와 규범을 준수한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  2. 회사는 거래업체의 전통과 특성을 존중한다.
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제19조(자유경쟁)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`회사는 자유경쟁의 원칙에 따라 시장경제질서를 존중하고\n경쟁사 및 경쟁제품과 선의의 경쟁을 추구한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제20조(상생추구)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  1. 회사는 협력업체와 상호이익 및 공동발전을 추구한다.
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사는 거래업체에 대한 우월적 지위를 이용하여 부당\n행위를 하지 않는다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제21조(정보보호)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`회사는 거래업체의 정보와 영업비밀을 보호하여야 하며,\n거래처의 동의가 있거나 법률상 허용되거나 제출 요구되는\n경우를 제외하고 제3자에게 누설하거나 공표하지 않는다.`}
                </Text>
                <br />
                <br />
                <Text $fontSize={'17px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제6장 주주에 대한 윤리</Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제22조(기업가치)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  1. 회사는 주식회사로서 주주가 곧 회사의 주인임을 인식한다.
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사는 효율 경영으로 이익창출을 통해 기업가치를\n극대화하고 그 성과를 주주와 공유한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제23조(주주권익)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 회사는 투명경영을 실천하며 주주의 정당한 요구 및\n제안을 존중한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사는 주주의 알 권리를 보장하며 적정, 정확, 적시에\n공시자료를 통해 회사의 경영상황을 알린다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`3. 회사는 미공개자료를 이용하여 내부적, 개인적 이익을\n취하거나 제3자에게 이익을 주지 않으며 주주의 이익보호를\n위해 최선을 다한다.`}
                </Text>
                <br />
                <br />
                <Text $fontSize={'17px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제7장 사회에 대한 윤리</Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제24조(사회발전 기여)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 회사는 합리적이고 효율적인 경영을 통해 성장과\n이익창출을 함으로써 사회적 부를 창조하여 사회 발전에\n이바지한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사는 사회의 일원으로서 지역사회의 요구와 제안을\n최대한 수용하고 문제를 해결하는데 최선을 다하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`3. 회사는 봉사활동, 재난구호, 기부 등 사회발전에\n도움이 되는 활동에 적극 참여한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제25조(정치적 중립)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 회사는 정치적 중립을 지키며, 회사의 유무형 자산, 인력\n등을 정치적 목적으로 사용하는 일체의 활동을 하지 않는다.\n다만, 세법에서 인정하는 적법한 정치 기부금은 예외로 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사는 사원의 참정권과 정치적 의사표현을 존중하며,\n사원은 회사 내부에서 정치적 활동을 하지 않는다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`3. 사원은 개인의 정치적 견해와 의사표현이 회사의\n입장으로 오해 받지 않도록 주의하여야 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제26조(노사화합)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`회사와 사원은 노사 모두가 회사 경영활동의 주체임을\n인식하고 신뢰와 협력을 바탕으로 건전한 노사문화를\n형성하여 사회의 모범이 된다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제27조(환경보호)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`회사는 환경보호 관련 국제기준 및 관계법령 등을 준수하고\n환경보호 및 오염방지를 위하여 환경친화적 경영을 한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제28조(재해예방)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`회사와 사원은 재해의 발생이 사회에 악영향을 미침을\n인식하며 보건 및 안전에 관한 사회 규범, 관계법령 및\n회사규정 안전관리규정 등을 준수하여 재해를\n예방하여야 한다.`}
                </Text>
                <br />
                <br />
                <Text $fontSize={'17px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제8장 보칙</Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제29조(책임자)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  1. 회사의 윤리경영에 대한 최종 책임은 대표이사에게 있다.
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 대표이사 이하 각 조직의 장은 본 규정 및 윤리경영에\n대해 소속직원을 감독, 관리, 교육할 책임이 있다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`3. 각 조직의 장은 연1회 이상의 정기적 교육을 실시하여야\n하며, 신입직원은 입사 후 즉시 교육하여야 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`4. 감사는 윤리책임자로서 대표이사를 보좌하며 제2항 및\n제3항을 감독 관리한다.`}
                </Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제30조(상담, 신고)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`1. 사원은 본 윤리규정 및 관계법령, 기타 사회규범에\n어긋나는 사실에 대하여 인지하는 즉시 상사에게 보고하며,\n상황에 따라 보고가 여의치 않은 경우 윤리책임자에게 상담\n신청 및 신고를 한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`2. 회사는 본 윤리규정 및 관계법령, 기타 사회규범에\n어긋나는 사실에 대한 기명 혹은 익명의 제보를 받는 장치를\n설치하고 윤리책임자가 감독 관리한다.`}
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  3. 윤리책임자는 상담자, 신고자, 제보자를 보호하여야 한다.
                </Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  {`4. 윤리책임자는 상담, 신고, 제보 사실에 대해 조사 확인하고\n필요한 조치를 취하여야 한다.`}
                </Text>
                <br />
                <br />
                <Text $fontSize={'17px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>부칙</Text>
                <br />
                <Text $fontSize={'14px'} $fontWeight="500" $color="#FFFFFF" style={{textAlign: 'left'}}>제1조(시행일)</Text>
                <Text $fontSize={'14px'} $fontWeight="300" $color="#C6C6C6" style={{textAlign: 'left', whiteSpace: 'break-spaces'}}>
                  이 규정은 2018년 1월 1일부터 시행한다.
                </Text>
              </div>
            </Wrapper>
          </div>
        </Mobile>
      </div>

      <Footer />
    </Container>
  );
};

export default Moral;
