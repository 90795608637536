import React, {useEffect, useState} from 'react';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import {Container, HomeComponentWrap, TextWrap, Text, Tab, TabItem} from '../aboutus/style';
import {HeadLine, Path, MainImgWrap, ContainerGridLineWrap, GridLineBox} from '../../../components/style';
import Management from '../aboutus/components/Leadership';
import Advisors from '../aboutus/components/Advisors';
import {useRecoilState} from 'recoil';
import {Desktop, Mobile} from '../../../utils/MediaQuery';

import Video from '../../../components/Video';
import {Trans} from 'react-i18next';
import {t} from 'i18next';
import MoveUp from '../../../atom/MoveUp';
import Language from '../../../atom/Language';
import {useRecoilValue} from 'recoil';

const Leadership = () => {
  const language = useRecoilValue(Language);
  const [tabNames, setTabNames] = useState(['Management', 'Advisors']);
  const [currentTab, setCurrentTab] = useState('Management');
  const [moveUp, setMoveUp] = useRecoilState(MoveUp);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('.container')?.scrollTo(0, 0);

    const handleScroll = () => {
      document.addEventListener('scroll', () => {
        const leader = document.querySelectorAll('#leadership');

        if (leader[0]?.getBoundingClientRect().y < window.innerHeight * 0.9) {
          leader[1]?.classList.add('moveup');
          setMoveUp(true);
        } else {
          leader[1]?.classList.remove('moveup');
        }
      });
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const leaders = document.querySelectorAll('#leadership');
    if (moveUp && currentTab === 'Management') {
      leaders[1]?.classList?.add('moveup');
    }
  }, [currentTab]);

  return (
    <Container className="container">
      <Header />
      <Path>
        <span style={{opacity: '0.8'}}>{`HOME > ABOUT US > `}</span>
        {t('leadership.title')}
      </Path>
      <MainImgWrap>
        <Video
          page="aboutus"
          src={
            window.innerWidth > 1280
              ? 'https://aribio.s3.ap-northeast-2.amazonaws.com/static/AB0400PB_VD.mp4'
              : window.innerWidth > 900
              ? 'https://aribio.s3.ap-northeast-2.amazonaws.com/static/AB1300PB_VD.mp4'
              : 'https://aribio.s3.ap-northeast-2.amazonaws.com/static/AB2200PB_VD.mp4'
          }
        />
      </MainImgWrap>
      <HomeComponentWrap style={{height: '100vh'}}>
        <HeadLine $className="midsize">
          <Trans i18nKey="leadership.title" components={{1: <br />}} />
        </HeadLine>
        <img
          src={process.env.PUBLIC_URL + '/assets/icons/scroll-button.svg'}
          alt="home"
          style={{
            position: 'absolute',
            right: '7vw',
            bottom: window.innerWidth > 900 ? '5vw' : '7vh',
            height: window.innerWidth > 1280 ? '24px' : '14px',
          }}
        />
      </HomeComponentWrap>
      <div style={{margin: '0', padding: '0', position: 'relative'}}>
        <Desktop>
          <Tab style={{padding: '0 7vw'}}>
            {tabNames.map((item, index) => (
              <TabItem
                key={index}
                $isActive={currentTab === item ? true : false}
                onClick={() => {
                  setCurrentTab(item);
                }}>
                {item}
              </TabItem>
            ))}
          </Tab>
          {currentTab === 'Management' ? (
            <Management tabShow={currentTab === 'Management'} />
          ) : (
            <Advisors tabShow={currentTab === 'Advisors'} />
          )}
        </Desktop>

        <Mobile>
          <Tab style={{padding: '0 5vw'}}>
            {tabNames.map((item, index) => (
              <TabItem
                key={index}
                $isActive={currentTab === item ? true : false}
                style={{fontSize: '20px', borderWidth: '1px'}}
                onClick={() => {
                  setCurrentTab(item);
                }}>
                {item}
              </TabItem>
            ))}
          </Tab>
          {currentTab === 'Management' ? <Management /> : <Advisors />}
        </Mobile>
      </div>

      <Footer />
    </Container>
  );
};

export default Leadership;
