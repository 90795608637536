import React, {useEffect, useState} from 'react';
import minus from '../../../../assets/images/aboutus/minus.svg';
import plus from '../../../../assets/images/aboutus/plus.svg';
import {
  HomeComponentWrap,
  Text,
  TabContentWrap,
  ContentBox,
  ContentBoxNameWrap,
  Image,
  DescriptionItem,
  CeoBox,
  MemberListWrap,
  TextWrap,
  HeadListWrap,
  HeadItemWrap,
} from '../style';
import {Desktop, Mobile} from '../../../../utils/MediaQuery';
import useLeadershipList from '../../../../hooks/company/useLeadershipList';
import Language from '../../../../atom/Language';
import {useRecoilValue} from 'recoil';
import {ContentWrap, HR} from '../../../../components/style';
import {t} from 'i18next';

const Leadership = () => {
  const [language] = useRecoilValue(Language);
  const {data, isLoading} = useLeadershipList('leadership', language);
  const [ceoContents, setCeoContents] = useState({});
  const [usContents, setUsContents] = useState([]);
  const [headContents, setHeadContents] = useState([]);

  useEffect(() => {
    setCeoContents(data?.data?.data?.ceo);
    setUsContents(data?.data?.data?.usList.map(item => {
      return {
        ...item,
        hide: true,
      };
    }));
    setHeadContents(data?.data?.data?.headList.map(item => {
      return {
        ...item,
        hide: true,
      };
    }));
  }, [data]);

  return (
    <HomeComponentWrap
      id="leadership"
      style={{
        width: '100vw',
        padding: '0',
        justifyContent: 'start',
        overflow: 'hidden',
      }}>
      <Desktop>
        <TabContentWrap id="leadership">
          {ceoContents && (
            <CeoBox
              style={{
                padding: window.innerWidth > 1280 ? '0 7vw 504px' : '0 7vw 255px',
              }}>
              <Image
                width={window.innerWidth > 1280 ? '835px' : '478px'}
                height={window.innerWidth > 1280 ? '555px' : '315px'}
                src={ceoContents?.fileDto?.fileUrl}
                alt="ceoProfilePicture"
              />
              <TextWrap
                style={{
                  width: window.innerWidth > 1280 ? '835px' : '478px',
                  height: window.innerWidth > 1280 ? '555px' : '315px',
                }}>
                <TextWrap
                  style={{
                    width: window.innerWidth > 1280 ? '500px' : '280px',
                  }}>
                  <Text
                    $align="start"
                    $color="#F2F2F2"
                    $fontWeight="500"
                    style={{
                      wordBreak: 'break-all',
                      margin: '0',
                      fontSize: window.innerWidth > 1280 ? '20px' : '11px',
                    }}>
                    {ceoContents?.position}
                  </Text>
                  <Text
                    $align="start"
                    $color="#F2F2F2"
                    $fontWeight="700"
                    style={{
                      wordBreak: 'break-all',
                      margin: '0',
                      marginBottom: '2vh',
                      fontSize: window.innerWidth > 1280 ? '36px' : '20px',
                    }}>
                    {ceoContents?.name}
                  </Text>
                  {/* <HR $width={window.innerWidth > 1280 ? '60px' : '24px'} style={{alignSelf:'start', marginTop: window.innerWidth > 1280 ? 75 : 29 }} /> */}
                  <Text
                    $align="start"
                    $color="#F2F2F2"
                    style={{
                      wordBreak: 'break-all',
                      margin: '0',
                      paddingLeft: window.innerWidth > 1280 ? '1rem' : '0.5rem',
                      fontSize: window.innerWidth > 1280 ? '18px' : '10px',
                    }}>
                    <ul>
                      {JSON.stringify(ceoContents?.contents)?.substring(1, JSON.stringify(ceoContents?.contents).length -1)?.split('\\n').map((item, index) => (
                        <DescriptionItem
                          key={index}
                          style={{
                            listStyle: 'disc',
                            lineHeight: window.innerWidth > 1280 ? '2rem' : '1.25rem',
                            fontSize: window.innerWidth > 1280 ? '18px' : '10px',
                            wordBreak: 'break-word'
                          }}>
                          {item.replace(/\\/g, '')}
                        </DescriptionItem>
                      ))}
                    </ul>
                  </Text>
                </TextWrap>
              </TextWrap>
            </CeoBox>
          )}
          <TextWrap
            style={{
              width: '100%',
              paddingLeft: '7vw',
            }}>
            <HR
              $width={window.innerWidth > 1280 ? '60px' : '24px'}
              style={{alignSelf: 'start', marginBottom: '1.5em'}}
            />
            <Text
              $fontSize={window.innerWidth > 1280 ? '34px' : '20px'}
              $fontWeight="500"
              $color="#E5E5E5"
              $align="start"
              style={{marginBottom: window.innerWidth > 1280 ? '150px' : '100px'}}>
              {t('home.aboutus.subtitle.us')}
            </Text>
          </TextWrap>
          <ContentWrap style={{padding: 0}}>
            <MemberListWrap>
              {usContents?.map((item, index) => (
                <ContentBox
                  key={index}
                  style={{
                    width: window.innerWidth > 1280 ? '454px' : '274px',
                  }}>
                  <Image
                    src={item?.fileDto?.fileUrl}
                    alt="leadershipphoto1"
                    width={window.innerWidth > 1280 ? '454px' : '274px'}
                    height="304px"
                    style={{marginBottom: window.innerWidth > 1280 ? '106px' : '24px'}}
                    $align="start"
                  />
                  <ContentBoxNameWrap>
                    <Text
                      $align="start"
                      $color="rgba(255,255,255,0.9)"
                      style={{
                        wordBreak: 'break-all',
                        margin: '0',
                        fontSize: window.innerWidth > 1280 ? '20px' : '12px',
                      }}>
                      {item?.position}
                    </Text>
                    <Text
                      $fontWeight="700"
                      $align="start"
                      style={{
                        wordBreak: 'break-all',
                        margin: window.innerWidth > 1280 ? '0.5rem 0 2.5rem 0' : '0.5rem 0 1.563rem 0',
                        fontSize: window.innerWidth > 1280 ? '34px' : '20px',
                      }}>
                      {item?.name}
                    </Text>
                    <p
                      href="#" 
                      style={{
                        textDecoration: 'none',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                        marginBottom: 12
                      }}
                      onClick={() => {
                        setUsContents(prevContents => prevContents.map(prevItem => {
                          if (prevItem.id === item.id) {
                            return {
                              ...prevItem,
                              hide: !item.hide
                            };
                          }

                          return prevItem;
                        }))
                      }}
                    >
                      { item.hide ? 'Read bio' : 'Hide bio' }
                    </p>
                    <div
                      style={{
                        overflow: 'hidden'
                      }}
                    >
                      <Text
                        $align="start"
                        $color="#F2F2F2"
                        style={{
                          margin: '0',
                          wordBreak: 'break-all',
                          fontSize: window.innerWidth > 1280 ? '18px' : '10px',
                          paddingLeft: window.innerWidth > 1280 ? '1.125rem' : '0.45rem',
                          transition: 'all .5s',
                          transform: item.hide ? 'translateY(-300px)' : 'translateY(0)',
                        }}>
                        <ul>
                          {JSON.stringify(item?.contents).substring(1, JSON.stringify(item?.contents).length - 1)?.split('\\n').map((item, index) => (
                            <DescriptionItem
                              key={index}
                              style={{
                                listStyle: 'disc',
                                wordBreak: 'break-word',
                                fontSize: window.innerWidth > 1280 ? '18px' : '10px',
                              }}>
                              {item.replace(/\\n/gi, ' ').replace(/\\r/gi, ' ')}
                            </DescriptionItem>
                          ))}
                        </ul>
                      </Text>
                    </div>
                  </ContentBoxNameWrap>
                </ContentBox>
              ))}
            </MemberListWrap>
          </ContentWrap>
          <TextWrap
            style={{
              padding: '0 7vw',
            }}>
            <HR
              $width={window.innerWidth > 1280 ? '60px' : '24px'}
              style={{alignSelf: 'start', marginBottom: '1.5em'}}
            />
            <Text
              $fontSize={window.innerWidth > 1280 ? '34px' : '20px'}
              $fontWeight="600"
              $color="#E5E5E5"
              $align="start"
              style={{marginBottom: window.innerWidth > 1280 ? '150px' : '120px'}}>
              {t('home.aboutus.subtitle.head')}
            </Text>
          </TextWrap>
          <ContentWrap style={{padding: 0}}>
            <HeadListWrap>
              {headContents?.map((item, index) => (
                <ContentBox
                  key={index}
                  style={{
                    width: window.innerWidth > 1280 ? '454px' : '274px',
                    marginBottom: window.innerWidth > 1280 ? '12.75vh' : '6vh',
                  }}>
                  <ContentBoxNameWrap>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      {/* <HR style={{backgroundColor: '#B1B1B1', width: 2, height:  window.innerWidth > 1280 ? '5rem' : '3.25rem', marginRight: '2.0833vw'}}/> */}
                      <TextWrap>
                        <Text
                          $align="start"
                          $color="rgba(255, 255, 255, 0.9)"
                          style={{
                            margin: '0',
                            wordBreak: 'break-all',
                            fontSize: window.innerWidth > 1280 ? '20px' : '12px',
                          }}>
                          {item?.position}
                        </Text>
                        <Text
                          $fontWeight="700"
                          $align="start"
                          style={{
                            wordBreak: 'break-all',
                            margin: '0.5rem 0 2.5rem 0',
                            fontSize: window.innerWidth > 1280 ? '34px' : '20px',
                          }}>
                          {item?.name}
                        </Text>
                      </TextWrap>
                    </div>
                    <p
                      href="#" 
                      style={{
                        textDecoration: 'none',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                        marginBottom: 12
                      }}
                      onClick={() => {
                        setHeadContents(prevContents => prevContents.map(prevItem => {
                          if (prevItem.id === item.id) {
                            return {
                              ...prevItem,
                              hide: !item.hide
                            };
                          }

                          return prevItem;
                        }))
                      }}
                    >
                      { item.hide ? 'Read bio' : 'Hide bio' }
                    </p>
                    <div
                      style={{
                        overflow: 'hidden'
                      }}
                    >
                      <Text
                        $align="start"
                        $color="#F2F2F2"
                        style={{
                          margin: 0,
                          fontSize: window.innerWidth > 1280 ? '18px' : '10px',
                          wordBreak: 'break-all',
                          // paddingLeft:  window.innerWidth > 1280 ? '3.625rem' : '2.25rem',
                          transition: 'all .5s',
                          transform: item.hide ? 'translateY(-300px)' : 'translateY(0)',
                        }}>
                        <ul style={{paddingLeft: window.innerWidth > 1280 ? '1rem' : '0.5rem'}}>
                          {JSON.stringify(item?.contents).substring(1, JSON.stringify(item?.contents).length - 1).split('\\n').map((item, index) => (
                            <DescriptionItem
                              key={item + index}
                              style={{
                                listStyle: 'disc',
                                fontSize: window.innerWidth > 1280 ? '18px' : '10px',
                                wordBreak: 'break-word'
                              }}>
                              {item.replace(/\\n/gi, ' ').replace(/\\r/gi, ' ')}
                            </DescriptionItem>
                          ))}
                        </ul>
                      </Text>
                    </div>
                  </ContentBoxNameWrap>
                </ContentBox>
              ))}
            </HeadListWrap>
          </ContentWrap>
        </TabContentWrap>
      </Desktop>

      <Mobile>
        {ceoContents && (
          <>
            <TabContentWrap style={{padding: '0 19px', marginBottom: '160px'}}>
              <Image
                src={ceoContents?.fileDto?.fileUrl}
                alt="ceoProfilePicture"
                style={{
                  width: '90vw',
                  height: 'auto',
                  marginBottom: '45px',
                }}
              />
              <TextWrap style={{width: '100%'}}>
                <Text
                  $align="start"
                  $color="#F2F2F2"
                  $fontWeight="500"
                  style={{
                    margin: '0',
                    fontSize: '15ox',
                  }}>
                  {ceoContents?.position}
                </Text>
                <Text
                  $align="start"
                  $color="#F2F2F2"
                  $fontWeight="700"
                  style={{
                    margin: '0',
                    marginBottom: '16px',
                    fontSize: '18px',
                  }}>
                  {ceoContents?.name}
                </Text>
              </TextWrap>
              <TextWrap style={{width: '100%'}}>
                <Text
                  $align="start"
                  $color="#F2F2F2"
                  style={{
                    margin: '0',
                    fontSize: '16px',
                    fontWeight: 300,
                  }}>
                  <ul style={{paddingLeft: '0.8rem'}}>
                    {JSON.stringify(ceoContents?.contents)?.substring(1, JSON.stringify(ceoContents?.contents).length - 1)?.split(language === 'K' ? '\\r\\n' : '\\n').map((item, index) => (
                      <DescriptionItem
                        key={index}
                        style={{
                          listStyle: 'disc',
                          fontSize: '16px',
                        }}>
                        {item.replace(/\\/g, '')}
                      </DescriptionItem>
                    ))}
                  </ul>
                </Text>
              </TextWrap>
            </TabContentWrap>
          </>
        )}
        <div
          style={{
            alignSelf: 'start',
            paddingLeft: '19px',
          }}>
          <HR $width="40px" style={{alignSelf: 'start', marginBottom: '1.5em'}} />
          <Text
            $fontSize={window.innerWidth > 1280 ? '34px' : '20px'}
            $fontWeight="600"
            $color="#E5E5E5"
            $align="start"
            style={{marginBottom: '48px'}}>
            {t('home.aboutus.subtitle.us')}
          </Text>
        </div>
        <TabContentWrap>
          {usContents?.map((item, index) => (
            <div
              id="fadeIn"
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '0 19px',
              }}
              key={'tabcontent' + index}>
              <ContentBox key={index}>
                <Image src={item?.fileDto.fileUrl} alt="leadershipphoto" style={{width: '90vw', height: 'auto'}} />
                <ContentBoxNameWrap>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <TextWrap>
                      <Text
                        $align="start"
                        $color="#F2F2F2"
                        $fontSize="20px"
                        style={{margin: '0', fontSize: '15px', wordBreak: 'break-all'}}>
                        {item?.position}
                      </Text>
                      <Text
                        $fontSize="18px"
                        $fontWeight="600"
                        $align="start"
                        style={{wordBreak: 'break-all', margin: '0'}}>
                        {item?.name}
                      </Text>
                    </TextWrap>
                    {item?.isOpen ? (
                      <Image
                        onClick={() => {
                          const newTabContents = usContents?.map((item, idx) => {
                            if (idx === index) {
                              return {...item, isOpen: !item?.isOpen};
                            }
                            return item;
                          });
                          setUsContents(newTabContents);
                        }}
                        src={minus}
                        alt="minus"
                        style={{height: '20px'}}
                      />
                    ) : (
                      <Image
                        onClick={() => {
                          const newTabContents = usContents.map((item, idx) => {
                            if (idx === index) {
                              return {...item, isOpen: !item?.isOpen};
                            }
                            return item;
                          });
                          setUsContents(newTabContents);
                        }}
                        src={plus}
                        alt="plus"
                        style={{height: '20px'}}
                      />
                    )}
                  </div>
                </ContentBoxNameWrap>
                {item?.isOpen && (
                  <ul style={{paddingLeft: '1.4rem'}}>
                    {JSON.stringify(item?.contents)?.substring(1, JSON.stringify(item?.contents).length - 1)?.split('\\r\\n').map((item, index) => (
                      <DescriptionItem
                        key={index}
                        style={{
                          wordBreak: 'keep-all',
                          listStyle: 'disc',
                          fontSize: '16px',
                        }}>
                        {item.replace(/\\n/gi, ' ').replace(/\\r/gi, ' ')}
                      </DescriptionItem>
                    ))}
                  </ul>
                )}
              </ContentBox>
            </div>
          ))}
        </TabContentWrap>
        <div
          style={{
            alignSelf: 'start',
            paddingLeft: 19,
          }}>
          <HR $width="40px" style={{alignSelf: 'start', marginBottom: '1.5em'}} />
          <Text
            $fontSize={window.innerWidth > 1280 ? '34px' : '20px'}
            $fontWeight="600"
            $color="#E5E5E5"
            $align="start"
            style={{marginBottom: 48}}>
            {t('home.aboutus.subtitle.head')}
          </Text>
        </div>
        <TabContentWrap>
          {headContents?.map((item, index) => (
            <div
              id="fadeIn"
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '0 19px',
              }}
              key={'tabcontent' + index}>
              <HeadItemWrap key={index}>
                <ContentBoxNameWrap>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <TextWrap>
                      <Text
                        $align="start"
                        $color="#F2F2F2"
                        $fontSize="20px"
                        style={{wordBreak: 'break-all', margin: 0, fontSize: '15px'}}>
                        {item?.position}
                      </Text>
                      <Text
                        $fontSize="18px"
                        $fontWeight="600"
                        $align="start"
                        style={{wordBreak: 'break-all', margin: 0}}>
                        {item?.name}
                      </Text>
                    </TextWrap>
                    {item?.isOpen ? (
                      <Image
                        onClick={() => {
                          const newTabContents = headContents?.map((item, idx) => {
                            if (idx === index) {
                              return {...item, isOpen: !item?.isOpen};
                            }
                            return item;
                          });
                          setHeadContents(newTabContents);
                        }}
                        src={minus}
                        alt="minus"
                        style={{height: '20px'}}
                      />
                    ) : (
                      <Image
                        onClick={() => {
                          const newTabContents = headContents?.map((item, idx) => {
                            if (idx === index) {
                              return {...item, isOpen: !item?.isOpen};
                            }
                            return item;
                          });
                          setHeadContents(newTabContents);
                        }}
                        src={plus}
                        alt="plus"
                        style={{height: '20px'}}
                      />
                    )}
                  </div>
                </ContentBoxNameWrap>
                {item?.isOpen && (
                  <ul style={{paddingLeft: '1.4rem'}}>
                    {JSON.stringify(item?.contents)?.substring(1, JSON.stringify(item?.contents).length - 1)?.split('\\r\\n').map((item, index) => (
                      <DescriptionItem
                        key={index}
                        style={{
                          wordBreak: 'break-all',
                          listStyle: 'disc',
                          fontSize: '16px',
                        }}>
                        {item.replace(/\\n/gi, ' ').replace(/\\r/gi, ' ')}
                      </DescriptionItem>
                    ))}
                  </ul>
                )}
              </HeadItemWrap>
            </div>
          ))}
        </TabContentWrap>
      </Mobile>
    </HomeComponentWrap>
  );
};

export default Leadership;
