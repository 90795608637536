import React from 'react';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import {Container, HomeComponentWrap, TextWrap, Text} from './style';
import {HeadLine, Path, MainImgWrap} from '../../../components/style';
import {Desktop, Mobile} from '../../../utils/MediaQuery';

import Video from '../../../components/Video';
import {Trans} from 'react-i18next';
import {t} from 'i18next';
import Language from '../../../atom/Language';
import {useRecoilValue} from 'recoil';

const AboutUs = () => {
  const language = useRecoilValue(Language);

  return (
    <Container className="container">
      <Header />
      <Path>
        <span style={{opacity: '0.8'}}>{`HOME > ABOUT US > `}</span>
        {t('aboutus.title')}
      </Path>
      <MainImgWrap>
        <Video
          page="aboutus"
          src={
            window.innerWidth > 1280
              ? 'https://aribio.s3.ap-northeast-2.amazonaws.com/static/AB0400PB_VD.mp4'
              : window.innerWidth > 900
              ? 'https://aribio.s3.ap-northeast-2.amazonaws.com/static/AB1300PB_VD.mp4'
              : 'https://aribio.s3.ap-northeast-2.amazonaws.com/static/AB2200PB_VD.mp4'
          }
        />
      </MainImgWrap>
      <HomeComponentWrap style={{height: '100vh'}}>
        <HeadLine $className="midsize">
          <Trans i18nKey="aboutus.headline" components={{1: <br />}} />
        </HeadLine>
        <img
          src={process.env.PUBLIC_URL + '/assets/icons/scroll-button.svg'}
          alt="home"
          style={{
            position: 'absolute',
            right: '7vw',
            bottom: window.innerWidth > 900 ? '5vw' : '7vh',
            height: window.innerWidth > 1280 ? '24px' : '14px',
          }}
        />
      </HomeComponentWrap>
      <div style={{margin: '0', padding: '0', position: 'relative'}}>
        <Desktop>
          <HomeComponentWrap>
            <TextWrap>
              <Text $color="#939598" $fontWeight="300" style={{fontsize: window.innerWidth > 1280 ? '26px' : '18px'}}>
                {t('aboutus.title')}
              </Text>
              <Text $fontWeight="500" style={{fontSize: window.innerWidth > 1280 ? '50px' : '34px'}}>
                {t('aboutus.subtitle')}
              </Text>
              <hr
                style={{
                  width: window.innerWidth > 1280 ? '60px' : '40px',
                  border: '1px solid #ffffff',
                  margin: '2.5rem 0 4rem 0',
                }}
              />
              <Text
                $fontWeight="300"
                $color="#D3D3D3"
                style={{
                  fontSize: window.innerWidth > 1280 ? (language !== 'KOR' ? '23px' : '21px') : '14px',
                  lineHeight: language !== 'KOR' ? '1.65em' : '1.85em',
                }}>
                <Trans i18nKey="aboutus.desc" components={{1: <br />}} />
              </Text>
            </TextWrap>
          </HomeComponentWrap>
        </Desktop>

        <Mobile>
          <HomeComponentWrap>
            <TextWrap style={{width: '100%'}}>
              <Text $color="#939598" $fontWeight="300" style={{fontSize: '16px'}}>
                {t('aboutus.title')}
              </Text>
              <Text $fontWeight="500" style={{fontSize: '23px'}}>
                {t('aboutus.subtitle')}
              </Text>
              <hr style={{width: '20px', border: '1px solid #ffffff', margin: '0 0 2rem 0'}} />
              <Text
                $fontSize="18px"
                $fontWeight="300"
                $color="#D3D3D3"
                style={{lineHeight: '20px', fontSize: language !== 'KOR' ? '18px' : '16px'}}>
                <Trans i18nKey="aboutus_m.desc" components={{1: <br />}} />
              </Text>
            </TextWrap>
          </HomeComponentWrap>
        </Mobile>
      </div>

      <Footer />
    </Container>
  );
};

export default AboutUs;
